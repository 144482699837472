var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "minting-single" },
    [
      _c(
        "DefaultCharacter",
        {
          attrs: {
            isLoadingData: _vm.isLoadingData,
            character: _vm.GET_CHARACTER,
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("market-place-header", { attrs: { urls: _vm.getPathUrls } })],
            1
          ),
          _vm.GET_CHARACTER
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c("btn", {
                    staticClass: "avatar-btn",
                    attrs: {
                      type: "icon-gradient",
                      text: `MINT ${_vm.toLocaleFormat(
                        _vm.GET_CHARACTER.price
                      )} MF`,
                      hoverText: `MINT ${
                        _vm.toLocaleFormat(_vm.GET_CHARACTER.usd_price) || 100
                      } $`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onNftMintClick(_vm.GET_CHARACTER)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("approve-popup", {
        ref: "approvePopup",
        attrs: { "action-btn-text": "Mint" },
        on: { "action-btn-click": _vm.mintCharacter },
      }),
      _c("mint-success-popup", { ref: "mintSuccessPopup" }),
      _vm.isMinting
        ? _c("preloader", { attrs: { translucent: "", black: "" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }